/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  TrophyFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: t('Pools'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: 'NFTs',
        icon: TrophyFillIcon,
        href: 'https://www.mgp-nfts.com/',
        type: DropdownMenuItemType.EXTERNAL_LINK,
        showItemsOnMobile: false,
        items: []
      },
      {
        label: "",
        href: "#",
        icon: MoreIcon,
        items: [
          {
            label: "MTHAIBAHT",
            href: "https://mthaibaht.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MGP-Mining(V1)",
            href: "https://mgp-platform.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MGP-Mining(V2)",
            href: "https://mgp-mining.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MCASH Saving",
            href: "https://mcash-saving.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MCOIN Saving",
            href: "https://saving-mmsc.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MMS Coin",
            href: "https://mms-soilminerals.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MMS Cash",
            href: "https://mcash-exchangepay.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MHAPPY",
            href: "https://mhappy-stablecointhai.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "MGP Malls",
            href: "https://www.mgpmalls.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "NFT",
            href: "https://www.mgp-nfts.com/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Buy Mcash Direct",
            href: "https://mcash-exchangepay.com/buymcash/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },

        ],
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config